<template>
  <v-dialog v-model="visible" max-width="600">
    <v-card >
      <v-card-title>
        Calculate a leaderboard goal
      </v-card-title>

      <v-card-subtitle>Duration</v-card-subtitle>
      <v-card-text>
        {{ days }} days (between {{ race.from || event.from | localDate }} and {{ race.till || event.till | localDate }}).
      </v-card-text>

      <div v-if="collectiveMode">
      <v-card-subtitle>Number of participants</v-card-subtitle>
      <v-card-text>
        <DistanceTextArea
          v-model="numberOfParticipants" 
          label="Estimated number of participants" 
          :mode="$helpers.UnitType.NUMBER"
          hint="You can always change this later  on"
          />
      </v-card-text>
      </div>

      <v-card-subtitle>Daily Goal</v-card-subtitle>
      <v-card-text>
        <DistanceTextArea
          v-model="dailyValue" 
          :unit="event.unit"
          :label="eventUtil.goalLabel()" 
          :mode="eventUtil.goalDistanceUnitType()"
          :multiplier="eventUtil.goalDistanceMultiplier()"
          hint="This goal is per day"
          />
      </v-card-text>

      <v-card-subtitle>Calculated Challenge Goal</v-card-subtitle>
      <v-card-text>
        {{ eventUtil.goalLabel() }}: <strong>{{ eventUtil.getValueForDisplay(calculatedValue) }}</strong>
      </v-card-text>
      
      <v-card-actions>
        <v-btn color="primary" text @click="visible=false">Close</v-btn>
        <v-spacer />
        <v-btn :disabled="!dailyValue" color="primary" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import DistanceTextArea from "@/components/DistanceTextArea";

export default {
  name: "CalculateRaceGoalDialog",
  components: {
    DistanceTextArea,
  },
  props: {
    value: Number,
    event: Object,
    race: Object,
    collectiveMode: Boolean,
  },
  data() {
    return {
      siteData: siteData,
      visible: false,
      mapTemplates: null,
      dailyValue: 0,
      numberOfParticipants: 0,
    };
  },
  async mounted() {
  },
  methods: {

    async open() {
      if (this.race && this.race.scoring == 'STEPS') {
        this.dailyValue = 7500;
      }
      if (this.race && this.race.scoring == 'TIME') {
        this.dailyValue = 3600 / 2;
      }
      if (this.race && this.race.scoring == 'DISTANCE') {
        this.dailyValue = 5000;
      }

      this.visible = true;
    },

    async confirm() {
      this.$emit('input', this.calculatedValue);
      this.visible = false;
    }
  },

  computed: {
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },

    days() {
      return this.event && Math.round(this.$helpers.getDurationForEvent(this.event).length('days'));
    },

    calculatedValue() {
      return this.dailyValue * this.days * (this.collectiveMode ? this.numberOfParticipants : 1);
    },
  },

};
</script>
<style lang="scss">
</style>

