<template>
  <div>
    <!-- <v-breadcrumbs :items="breadcrumbItems"/> -->
    <div class="mt-8 mb-2 align-right">
      <v-btn v-if="event" text color="primary" @click="state='skip'" exact>
        <v-icon small class="me-1">fa-times-circle</v-icon>
        Cancel
      </v-btn>
    </div>
    <v-card v-if="template && request" class="">
      <page-header :title="`Configure ${template.name} challenge`" :img="template.img" :mini="step > 1" :compact="step == 1" />

      <v-stepper v-model="step" tile :elevation="0">
        <v-stepper-header>
          <v-stepper-step v-if="wizardState.general.visible" :editable="step > wizardState.general.step" :complete="step > wizardState.general.step" :step="wizardState.general.step">
            Event Basics
          </v-stepper-step>

          <v-divider  v-if="wizardState.general.visible" />

          <v-stepper-step v-if="wizardState.parameters.visible" :editable="step > wizardState.parameters.step" :complete="step > wizardState.parameters.step" :step="wizardState.parameters.step">
            Options 
            <small v-if="request.parameters && request.parameters.length==0">Optional</small>
          </v-stepper-step>

          <v-divider  v-if="wizardState.parameters.visible" />

          <v-stepper-step v-if="wizardState.decorators.visible" :editable="step > wizardState.decorators.step" :complete="step > wizardState.decorators.step" :step="wizardState.decorators.step">
            Configuration
            <small v-if="configurableDecorators(request.decorators).length==0">Optional</small>
          </v-stepper-step>

          <v-divider  v-if="wizardState.decorators.visible" />

          <v-stepper-step v-if="wizardState.leaderboards.visible" :editable="step > wizardState.leaderboards.step" :complete="step > wizardState.leaderboards.step" :step="wizardState.leaderboards.step">
            Leaderboards
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content v-if="wizardState.general.visible" :step="wizardState.general.step">
            <v-form v-if="appendMode" ref="formGeneral" v-model="valid" lazy-validation>
              <h3>Sub-challenge Name</h3>
                
              <p class="mb-0">This name will be added to the leaderboards of this sub-challenge.</p>
              <v-text-field 
                v-model="request.append_config.name" 
                :rules="$helpers.ValidationRules.name" 
                autofocus
                label="How would you like to name this sub-challenge? (required)" 
                prepend-icon="fa-pencil-alt"
                required
                />
                
              <h3 class="mt-4">Time Window</h3>
              <p class="mb-0">Select the time window you'd like to use for the sub-challenge.</p>
              <v-row>
                <v-col cols="12" md="6" class="">
                  <DateWithTimeZonePicker v-model="request.append_config.from" :timeZone="event.timezone" label="Start date (included)" />
                </v-col>
                <v-col cols="12" md="6" class="">
                  <DateWithTimeZonePicker v-model="request.append_config.till" :timeZone="event.timezone" label="Last date (included)" />
                </v-col>
              </v-row>
            </v-form>
            <v-form v-else ref="formGeneral" v-model="valid" lazy-validation>
              <h3>General Settings</h3>
                
              <p class="mb-0">The name is used in the app and in emails to identify this event. You can change the name later on.</p>
              <v-text-field 
                v-model="event.name" 
                :rules="$helpers.ValidationRules.name" 
                autofocus
                label="How would you like to name your event? (required)" 
                prepend-icon="fa-pencil-alt"
                required
                />

            <h3 class="mt-4">Event Window</h3>
            <p class="mb-0">Select the event window. Only activities between open and close dates may count for this event. You may start inviting users to join before the start date and keep access to the data after the close date.</p>
            <v-row>
              <v-col cols="12" md="6" class="">
                <DateWithTimeZonePicker v-model="event.from" :timeZone="event.timezone" label="Start date (included)" />
              </v-col>
              <v-col cols="12" md="6" class="">
                <DateWithTimeZonePicker v-model="event.till" :timeZone="event.timezone" label="Last date (included)" />
              </v-col>
            </v-row>
            <h3 class="mt-0">Organization</h3>
            <v-radio-group :value="event.org ? 'LINK' : 'NONE'" hide-details class="mt-0">
              <v-radio
                disabled
                value="NONE"
                label="Not linked to an organization"
                />

              <v-radio
                value="LINK"
                label="Linked with an existing organization"
                disabled
                />
              <v-text-field 
                v-if="event.org"
                disabled
                :value="event.org && event.org.name"
                class="ml-8"
                />
              </v-radio-group>     
              <p>
                <v-btn text color="primary" :to="{ name: 'eventmanagerLinkOrg', params: { id: this.event.id }}" exact>Change</v-btn>
              </p>
            </v-form>
            <div class="d-flex mt-4">
              <v-spacer />
              <v-btn text color="primary" class="me-4" @click="state='skip'">Skip</v-btn>
              <v-btn color="primary" large @click="confirmGeneral">Next</v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="wizardState.parameters.visible" :step="wizardState.parameters.step">
            <v-form ref="formParameters" v-model="valid" lazy-validation>
              <div v-if="request.parameters">
                <h3>Configuration Options</h3>
                <div v-for="(item, idx) in request.parameters" :key="idx" class="mb-4">
                  <v-divider v-if="idx > 0" :key="`${idx}_divider`" class="mt-4" />
                  <v-switch 
                    v-model="item.enabled"
                    hide-details
                    class="font-large"
                    @change="handleParameterChange(item)"
                    >
                    <template v-slot:label>
                      {{ item.label }}
                    </template>
                  </v-switch>
                  <p v-if="item.description" :class="{'mt-2': true, 'grey--text': !item.enabled}">{{item.description}}</p>
                </div>
              </div>
              <p v-if="!request.parameters || !request.parameters.length">
                There are no configuration options available for the selected template.
              </p>
            </v-form>
            <div class="d-flex mt-4">
              <v-btn v-if="wizardState.parameters.step > 1" text @click="step--">Back</v-btn>
              <v-spacer />
              <v-btn color="primary" large @click="confirmParameters">Next</v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="wizardState.decorators.visible" :step="wizardState.decorators.step">
            <v-form ref="formConfig" v-model="valid" lazy-validation>

              <h3>Event Configuration</h3>

              <template v-for="(item, idx) in configurableDecorators(request.decorators)">
                <v-divider v-if="idx > 0" :key="`${idx}_divider`" />
                <decorator-editor :decorator="item" :event="event" :template="template" :mapTemplates="mapTemplates" :wellbeingPacks="wellbeingPacks" type="EVENT" :key="idx" @change="handleDecoratorChange(item, request.decorators)" />
              </template>
              <p v-if="configurableDecorators(request.decorators).length == 0">
                There are no configuration options available for the selected template.
              </p>
            </v-form>

            <div class="d-flex mt-4">
              <v-btn v-if="wizardState.decorators.step > 1" text @click="step--">Back</v-btn>
              <v-spacer />
              <v-btn color="primary" large @click="confirmConfiguration">Next</v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content v-if="wizardState.leaderboards.visible" :step="wizardState.leaderboards.step">
            <v-form ref="formLeaderboards" v-model="valid" lazy-validation>

              <h3 v-if="configurableLeaderboards && configurableLeaderboards.length" class="mb-2">Leaderboard Configuration</h3>
              <p>Leaderboards are used to display the results of this event. An event can have one or more leaderboards to show different result sets.</p>
              <v-row>
              <v-col v-for="(leaderboard, idy) in configurableLeaderboards" :key="`${idy}_race`" cols="12" md="6">
                <v-card color="grey lighten-4">
                  <v-card-subtitle>Leaderboard: {{ leaderboard.name }}</v-card-subtitle>
                  <v-card-text class="pb-0">
                    <span v-if="!leaderboard.activity_types || leaderboard.activity_types.length == 0">
                      <v-icon small class="mr-2">fa-ellipsis-h</v-icon>
                      {{ $t('events.race.all-sports') }},
                    </span>
                    <span v-else v-for="(type, idx) in leaderboard.activity_types" :key="idx">
                      <v-icon v-if="idx==0" small class="mr-2">{{ $helpers.getActivityIcon(type) }}</v-icon>
                      {{  idx == 0 ? $helpers.getActivityTypeLabel(type, template, leaderboard) : $helpers.getActivityTypeLabel(type, template, leaderboard).toLowerCase() }}
                      <span v-if="idx != leaderboard.activity_types.length-1" class="mr-0">{{ $t('shared.or') }}</span>
                      <span v-else class="mr-0">•</span>
                    </span>
                    {{leaderboard.team_type == 'TEAM' ? $t('events.race.team') :$t('events.race.individual')}}
                  </v-card-text>
                  <v-card-text class="pb-0">
                    <vue-markdown v-if="leaderboard.description" class="markdown" :html="false">{{leaderboard.description }}</vue-markdown>
                  </v-card-text>
                  <v-card-text class="pt-0">
                    <template v-for="(item, idz) in configurableDecorators(leaderboard.decorators)">
                      <v-divider  :key="`${idy}_${idz}_divider`" />
                      <decorator-editor :decorator="item" :event="event" :template="template" :mapTemplates="mapTemplates" :wellbeingPacks="wellbeingPacks" :leaderboard="leaderboard" type="RACE" :key="`${idy}_${idz}`" @change="handleDecoratorChange(item, leaderboard.decorators)" />
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
              </v-row>

            </v-form>
            <div v-if="response">
              <v-alert v-if="response.meta.status === 'OK'" type="success">All good!</v-alert>
              <v-alert v-if="response.meta.status === 'INFO'" type="info">Some items might need your attention.</v-alert>
              <v-alert v-if="response.meta.status === 'ERROR'" type="error">Please fix these validation message and try again.</v-alert>
              <ul class="mb-4">
                <li v-for="(item,idx) in response.data" :key="idx">{{item.msg}}</li>
              </ul>
            </div>            
            <div class="d-flex mt-4">
              <v-btn v-if="wizardState.leaderboards.step > 1" text @click="step--">Back</v-btn>
              <v-spacer />
              <v-btn color="primary" large @click="submit" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">Finalize</v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
<!-- 
      <v-card-text v-if="event && request">
        <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
      </v-card-text>      

      <v-card-text>   
        
        <v-btn class="mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
        <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
      </v-card-text>    -->
    </v-card>
    <v-dialog v-if="state == 'created' && event" v-model="state" max-width="500">
      <v-card>
        <v-card-title>Ready to configure.</v-card-title>
        <v-card-text>
          You can now finalize the setup and publish your event when you're ready to start inviting the participants.
        </v-card-text>
        <v-card-text>
          If you're not yet sure about the rules for your event, you can also complete these steps later.
        </v-card-text>
        <v-card-actions>
          <v-btn outlined color="primary" :to="{ name: 'eventmanagerView', params: { id: this.event.id }}" exact>Finalize Later</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="state = null;">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    <v-dialog v-if="state == 'skip' && event" v-model="state" max-width="500">
      <v-card>
        <v-card-title>Finalize later?</v-card-title>
        <v-card-text>
          If you're not yet sure about the rules for your event, you can complete these steps later.
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="state = null;">Cancel</v-btn>
          <v-spacer />
          <v-btn color="primary" :to="{ name: 'eventmanagerView', params: { id: this.event.id }}" exact>Finalize Later</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import assetsService from "@/services/assetsService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import SimpleTooltip from '@/components/SimpleTooltip.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventHeader from "@/components/EventHeader.vue";
import PageHeader from '@/components/PageHeader.vue';
import RegFieldEditor from "@/components/RegFieldEditor.vue";
import ToggleButtonInput from "@/components/ToggleButtonInput.vue";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
import DecoratorEditor from '../../components/eventmanager/DecoratorEditor.vue';
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ToggleButtonInput,
    SimpleTooltip,
    SupportButton,
    PageHeader,
    MarkdownEditor,
    VueMarkdown,
    EventHeader,
    Header,
    DecoratorEditor,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      mapTemplates: null,
      wellbeingPacks: null,
      state: null,
      valid: true,
      step: 1,
      error: null,
      event: null,
      originalEvent: null,
      template: null,
      request: null,
      response: null,
      appendMode: false,
      appendRequest: {},
    };
  },
  async mounted() {
    await this.getProfile();
    this.state = this.$route.query.state;
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async confirmGeneral() {
      // todo: save event
      if (this.appendMode) {
        // todo
      }
      else if (
        this.event.name != this.originalEvent.name
        || this.event.from != this.originalEvent.from
        || this.event.till != this.originalEvent.till
        ) {
          this.eventUtil.save();
          this.originalEvent = { ...this.event };
      }
      this.step ++;
    },
    async confirmParameters() {
      this.step ++;

      // if (this.configurableDecorators(this.request.decorators).length == 0) {
      //   // skip step if there's nothing to do.
      //   this.step ++;
      // }

    },
    
    async confirmConfiguration() {
      this.step ++;
    },

    async submit() {
      
      console.log('/// save request', this.request);
      //return;
      this.response = (await eventManagerService.applyEventTemplate(this.event.id, this.request)).data;
      if (this.response.meta.status == 'OK') {
        this.$helpers.toastResponse(this, this.response, 'Your event has been configured and is ready to go 👍.');
        this.eventUtil.refresh();
        this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }, query: { state: 'template_configured' }});
      }
      console.log('/// save response', this.response);
      return;
      if (this.$refs.form.validate()) {
        this.error = null;
        try {
          var success = await this.eventUtil.save();
          if (success) {
            this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }});
            return;
          }
          else {
            this.error = response.data.msg;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    handleParameterChange(parameter) {
      var decorators = this.request.decorators.filter(x => x.if_parameter == parameter.key);
      console.log('/// change parameter', parameter.key, decorators);
      for (var decorator of decorators){
        //if (!decorator.required) {
          decorator.enabled = parameter.enabled;
        //}
      }
    },

    handleDecoratorChange(decorator, collection) {
      console.log('/// change', decorator.type, decorator.enabled, decorator.dependencies);
      if (decorator.enabled && decorator.hasOwnProperty('dependencies') && decorator.dependencies) {
        for (var type of decorator.dependencies ) {
          var dependency = collection.find(x => x.type == type);
          if (dependency) {
            dependency.enabled = true;
          this.$set(dependency, dependency.enabled, true);
          }
        }
      }
      //   for (var x of this.template.decorators) {
      // console.log('- turn on', x.type);
      //     //x.enabled = true;
      //     //this.$set(x, x.enabled, true);
      //   }
    },

    configurableDecorators(decorators) {
      return decorators
          && decorators.filter(x => !x.if_parameter || this.isParameterEnabled(x.if_parameter));
    },

    // isDecoratorEnabled(type) {
    //   return this.template && this.request.decorators && this.request.decorators.some(x => x.type == type && x.enabled);
    // },

    isParameterEnabled(key) {
      return this.template && this.request.parameters && this.request.parameters.some(x => x.key == key && x.enabled);
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.originalEvent = { ...this.event };
      this.appendMode = this.$route.query.appendTemplateId != null;
      var templateId = this.eventUtil.getMetaStringValue('cfg.template.id') || this.$route.query.template || this.$route.query.appendTemplateId;
      this.template = (await assetsService.getEventTemplate(templateId)).data;
      this.mapTemplates = (await assetsService.getMapTemplates()).data.data;
      this.wellbeingPacks = (await assetsService.getWellbeingPacks()).data.data;
      //console.log('/// template', this.template);
      
      this.request = {
        id: this.template.id,
        append_config: this.appendMode ? {
          name: this.template.name,
          from: this.event.from,
          till: this.event.till,
        } : null,
        parameters: (this.template.parameters || []).map(x => ({
          ...x,
          enabled: false,
        })),
        decorators: this.template && (this.template.decorators || []).map(x => ({
          ...x,
          enabled: x.enabled || false,
          config: x.defaults ? { ...x.defaults } : {},
        })),
        leaderboards: (this.template.leaderboards || []).map(race => ({
          ...race,
          decorators: race.decorators && race.decorators.map(x => ({
            ...x,
            enabled: x.enabled || false,
            config: x.defaults ? { ...x.defaults } : {},
          })),
        })),
      };

      if (this.$route.query.state == 'created') {
        this.step = 2;
      }

      console.log('/// request', this.request);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    wizardState() {
      let generalState = { visible: true, step: 1 };
      let parameterState = { visible: this.request.parameters.length > 0, step: 2 };
      let decoratorState = { visible: this.configurableDecorators(this.request.decorators).length > 0, step: parameterState.visible ? 3 : 2 };
      let leaderboardState = { visible: true, step: [generalState, parameterState, decoratorState].filter(x => x.visible).length + 1 };
      return {
        general: generalState,
        parameters: parameterState,
        decorators: decoratorState,
        leaderboards: leaderboardState,
      }
    },
    
    configurableLeaderboards() {
      return this.request
          && this.request.leaderboards
          && this.request.leaderboards.filter(x => !x.if_parameter || this.isParameterEnabled(x.if_parameter));
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Configure Options', disabled: true },
      ];
    },
    
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
.v-stepper .v-icon.fa-edit { display: none; }
</style>

