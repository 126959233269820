<template>
    <v-card 
        :class="{'card--featured': item.featured}"
        @click="hasClickListener ? $emit('click') : null" 
        :to="to ? to : (routeName ? {name: routeName, params: {id : item.id }} : null)"
        >
        <div v-if="item.featured" class="featured-badge" title="Featured">
          <v-icon color="black">fa-star</v-icon>
        </div>
        <v-img
            v-if="item.img"
            :src="item.img"
            class="white--text align-end relative"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
            >
            <v-card-title>{{item.name}}<v-icon color="white" small class="ms-2">fa-chevron-right</v-icon></v-card-title>
        </v-img>
    </v-card>
</template>
 
<script>

export default {
  name: "WellbeingPackCard",
  components: {
  },
  props: {
    item: Object,
    to: [Object, String],
    routeName: String,
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
      
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  }
};
</script>

<style lang="scss">
</style>

        